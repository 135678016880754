
import { PopupButton } from "react-calendly";

const CalendlyButton = () => {

    const colors = {
        clrTextLight: 'white',
        clrButtonBg1: 'red',
        clrButtonBg2: 'red',
    };

    const styles = {
        display: 'inline-block',
        padding: '.75em 1.5em',
        margin: '.5em auto',
        border: 'none',
        borderRadius: '.5em',
        color: colors.clrTextLight,
        fontWeight: '600',
        background: `linear-gradient(to left, ${colors.clrButtonBg1}, ${colors.clrButtonBg2})`
    };

    return <>
        <PopupButton
            className="button-calendly"
            iframeTitle="Book A Free Consultation"
            pageSettings={{
                backgroundColor: 'ffffff',
                hideEventTypeDetails: false,
                hideGdprBanner: true,
                hideLandingPageDetails: false,
                primaryColor: '00a2ff',
                textColor: '4d5055'
            }}
            rootElement={document.getElementById("root")}
            styles={styles}
            text="Book An Appointment!"
            url={process.env.REACT_APP_CALENDLY}
        />
    </>;
}
 
export default CalendlyButton;