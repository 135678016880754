
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import CalendlyInline from "./sub-components/CalendlyInline";

const Contact = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
    });

    return <>
		<CalendlyInline/>
	</>;
}

export default Contact;