
const Advantages = () => {

	const spanStyle = {
		'fontSize': '23px'
	};

	const backgroundColor = {
        backgroundColor: '#1c1e20',
    };

	
    return (
		<div id="services" className="sass-services-area pt-100 mb-100 pb-100" style={backgroundColor}>
			<div className="container justify-items-center">
				<div className="row align-items-center">
				    <div className="saas-banner-content">
						<h3>
							Let Us <span style={spanStyle}>Help You Grow</span> 
							&nbsp;Your <span style={spanStyle}></span>
							Business <span>By Teaching You</span> How To 
							<span style={spanStyle}>&nbsp;Automate </span> Your
							<span style={spanStyle}>&nbsp;Marketing, Customer Capture, And Sales</span>
						</h3>
					</div>
                    <div className="col-lg-6">
						<div className="row">
							<div className="col-lg-6 col-sm-6">
								<div className="sass-single-services-box">
									<i className="flaticon-data-storage"></i>
									<h3>Cloud Based Application</h3>
									<p>Get secure access to your clients and data on your laptop or mobile device</p>
								</div>
							</div>

							<div className="col-lg-6 col-sm-6">
								<div className="sass-single-services-box">
									<i className="flaticon-smartphone"></i>
									<h3>Taxes To Go!</h3>
									<p>Securely upload documents and ID's or view your tax return(s) with <strong>Taxes To Go</strong> mobile app</p>
								</div>
							</div>
						</div>
					</div>

					<div className="col-lg-6">
						<div className="row">
							<div className="col-lg-6 col-sm-6">
								<div className="sass-single-services-box">
									<i className="flaticon-secure"></i>
									<h3>Build & Grow</h3>
									<p>Our proven mentorship program will help you build and grow your business</p>
								</div>
							</div>

							<div className="col-lg-6 col-sm-6">
								<div className="sass-single-services-box">
									<i className="flaticon-smartphone"></i>
									<h3>Tax Business Accelerator</h3>
									<p>We mentor you to become a successful Tax Preparer.  Applications between May and October</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
    )
}

export default Advantages;