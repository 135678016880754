
import { InlineWidget } from "react-calendly";

const CalendlyInline = () => {

    return <>
        {/* <div className="pt-5" styles={{ height: '750px' }}> */}
        <div className="mt-100 mb-5">
            <br /><br />
            <InlineWidget
                iframeTitle="Book A Free Consultation"
                pageSettings={{
                    backgroundColor: 'ffffff',
                    hideEventTypeDetails: false,
                    hideGdprBanner: true,
                    hideLandingPageDetails: false,
                    primaryColor: '00a2ff',
                    textColor: '4d5055'
                }}
                styles={{
                    height: '750px'
                }}
                url={process.env.REACT_APP_CALENDLY}
            />
        </div>
    </>;
}
 
export default CalendlyInline;