
import CalendlyButton from '../sub-components/CalendlyButton';
import banner from "../assets/images/portfolio/pic1.webp";

const MainBanner = ({ resultRef }) => {
//F5F5F5 E3E3E3
	const fontProps = { whitesmoke:{color: '#F5F5F5'}, orange:{color: '#F2F230'}, size:{fontSize: '30px'}};
	// const backgroundColor = {
    //     backgroundColor: '#1c1e20',
    // };

    return (
		<div id="home" className="saas-banner-area mt-5">
		{/* <div id="home" className="saas-banner-area" style={backgroundColor}> */}
			<div className="d-table">
				<div className="d-table-cell">
					<div className="container">
						<div className="saas-banner-content">
							<h2 style={fontProps.whitesmoke}>
								I'll <span>teach you</span> how <span>to grow your</span> tax <span> business!</span>
							</h2>
						</div>
						<div className="saas-banner-img">
							<img src={banner} alt='banner 1' style={{borderRadius: '50px'}} />
						</div>
						<div className="saas-banner-content">
							<br /><br />
							<h3 style={fontProps.size}><span style={fontProps.whitesmoke}>We have the most advanced tax software and tools</span></h3>
							<h3 style={fontProps.whitesmoke}>* Top Bank Products * Work Virtual * Access From Any Device *</h3>
							<h3 style={fontProps.whitesmoke}>* <span>automatically collect fees!</span> * be in control of your business *</h3>
						</div>
						<button onClick={ () => {
								resultRef.current.scrollIntoView({ behavior: "smooth" })
							}} 
							className='button mx-3'
							>
							CLICK HERE FOR PROMOTIONS
						</button>
						<div>
							<br />
							<CalendlyButton />
						</div> 
					</div>
				</div>
			</div>
		</div>
    )
}

export default MainBanner;