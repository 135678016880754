
import React, { useEffect, useRef } from "react";
import MainBanner from "./sub-components/MainBanner";
import Advantages from "./sub-components/Advantages";
import Pricing from "./sub-components/Pricing";

const Home = () => {
    const resultRef = useRef(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return <>
        <MainBanner resultRef={resultRef}/>
        <Advantages />
        <Pricing ref={resultRef} />
    </>;
}
 
export default Home;