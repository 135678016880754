

import 'bootstrap/dist/css/bootstrap.min.css';
import './components/styles/animate.css';
import './components/styles/animate.css'
import './components/styles/boxicons.min.css';
import './components/styles/flaticon.css';
import '../node_modules/react-modal-video/css/modal-video.min.css';
import 'react-accessible-accordion/dist/fancy-example.css';
import './components/styles/style.css';
import './components/styles/responsive.css';
import './components/styles/button.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './components/Home';
import Contact from './components/Contact';
import Success from './components/sub-components/Success';
import Redirect from './components/sub-components/Redirect';
import CalendlyPopUp from './components/sub-components/CalendlyPopUp';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';

function App() {
  return <>
    <Router>
      <Navbar/>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/success' element={ <Success /> }/>
          <Route path='*' element={ <Redirect /> }/>
        </Routes>
      <Footer/>
      <CalendlyPopUp />
    </Router>
  </>;
}

export default App;
