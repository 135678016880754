
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import logo from "./assets/images/portfolio/logo.png";

const Footer = () => {

    const currentYear = new Date().getFullYear();
    const backgroundColor = {
        backgroundColor: '#1c1e20',
    };


    return (
        <>
            <div className="footer-area personal-footer-area pt-100 pb-70" style={backgroundColor}>
                <div className="container text-center">
                    {/* <div className="row"> */}
                        {/* <div className="col-lg-6 col-md-12"> */}
                            <div className="single-footer-widget">
                                <NavLink to="/">
                                    <img src={logo} alt="logo" />
                                </NavLink>
                                <p>
                                    Let Us <strong><span>Help You Grow</span></strong> 
                                    &nbsp;Your <span></span> Business 
                                    <strong><span> By Teaching You </span></strong> 
                                    How To<strong><span>&nbsp;Automate </span></strong>
                                    Your <strong><span>Marketing, Customer Capture, And Sales</span></strong>
                                </p>
                            </div>
                        {/* </div> */}

                        {/* <div className="col-lg-6 col-md-12 ">
                            <div className="single-footer-widget">
                                <h3>Contact us</h3>
                                <br />
                                <div className="social-area">
                                    <ul>
                                        <li>
                                            <a href="https://www.facebook.com/taxproaccelerator" target="_blank" rel='noreferrer'>
                                                <Icon icon="bx:bxl-facebook" className='icon'/>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://instagram.com/" target="_blank" rel='noreferrer'>
                                                <Icon icon="bx:bxl-instagram" className='icon'/>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> */}
                    {/* </div> */}
                </div>
            </div>
            
            <div className="personal-copy-right copy-right" style={backgroundColor}>
                <div className="container">
                    <span>
                        Copyright &copy;{currentYear} Metaverse Group. Designed by&nbsp;
                        <a href="https://metaversegroup.tech/contact" target="_blank" rel='noreferrer'>
                            Analitico
                        </a>
                    </span>
                </div>
            </div>
        </>
    );
}

export default Footer;