
import { PopupWidget } from "react-calendly";

const CalendlyPopUp = () => {

    return <>
        <PopupWidget
            branding
            color="red"
            iframeTitle="Book A Free Consultation"
            pageSettings={{
                backgroundColor: 'ffffff',
                hideEventTypeDetails: false,
                hideGdprBanner: true,
                hideLandingPageDetails: false,
                primaryColor: '00a2ff',
                textColor: '4d5055'
            }}
            rootElement={document.getElementById("root")}
            text="Book An Appointment!"
            textColor="#ffffff"
            url={process.env.REACT_APP_CALENDLY}
        />
    </>;
}
 
export default CalendlyPopUp;