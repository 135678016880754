
import React, { Component } from 'react';
import { Icon } from '@iconify/react';
import { NavLink } from "react-router-dom";
import logo from "./assets/images/portfolio/logo.png";


class Navbar extends Component {
    state = {
        collapsed: true
    };

    toggleNavbar = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    componentDidMount() {
        this._isMounted = true;
        // let elementId = document.getElementById("navbar");
        // document.addEventListener("scroll", () => {
        //     if (window.scrollY > 170) {
        //         elementId.classList.add("is-sticky");
        //     } else {
        //         elementId.classList.remove("is-sticky");
        //     }
        // });
        // window.scrollTo(0, 0);

        this.menuActiveClass()
    };

    menuActiveClass = () => {
        let mainNavLinks = document.querySelectorAll(".navbar-nav li a");
        window.addEventListener("scroll", () => {
            let fromTop = window.scrollY;
            mainNavLinks.forEach(link => {
                if (link.hash){
                    let section = document.querySelector(link.hash);
            
                    if (
                        section.offsetTop <= fromTop &&
                        section.offsetTop + section.offsetHeight > fromTop
                    ) {
                        link.classList.add("active");
                    } else {
                        link.classList.remove("active");
                    }
                }
            });
        });
    };

    render() {
        const { collapsed } = this.state;
        const classOne = collapsed ? 'collapse navbar-collapse' : 'navbar-collapse collapse show';
        const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';
        const backgroundColor = {
            backgroundColor: '#1c1e20',
        };
        return (
            <>
                <nav id="navbar" className="navbar navbar-expand-md personal-navbar" style={backgroundColor}>
                    <div className="container-fluid">
                        <NavLink to="/" className="navbar-brand">
                            <img src={logo} alt="logo" />
                        </NavLink>

                        <button 
                            onClick={this.toggleNavbar}
                            className={classTwo} 
                            type="button" 
                            data-toggle="collapse" 
                            data-target="#navbarSupportedContent" 
                            aria-controls="navbarSupportedContent" 
                            aria-expanded="false" 
                            aria-label="Toggle navigation"
                        >
                            <span className="icon-bar top-bar"></span>
                            <span className="icon-bar middle-bar"></span>
                            <span className="icon-bar bottom-bar"></span>
                        </button>

                        <div className={classOne} id="navbarSupportedContent">
                            <ul className="navbar-nav m-auto">
                                <li className="nav-item">
                                    <NavLink 
                                        onClick={this.toggleNavbar} 
                                        offset={100} 
                                        className="nav-link" 
                                        to="/"
                                    >
                                        Home
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink 
                                        onClick={this.toggleNavbar} 
                                        offset={-1} 
                                        className="nav-link" 
                                        to="/contact"
                                    >
                                        Contact
                                    </NavLink>
                                </li>
                            </ul>

                            {/* <div className="other-option">
                                <ul>
                                    <li>
                                        <a href="https://www.facebook.com/taxproaccelerator/" target="_blank" rel='noreferrer'>
                                            <Icon icon="bx:bxl-facebook" className='icon'/>
                                        </a>
                                        
                                    </li>
                                    <li>
                                        <a href="https://instagram.com/" target="_blank" rel='noreferrer'>
                                            <Icon icon="bx:bxl-instagram" className='icon'/>
                                        </a>
                                    </li>
                                </ul>
                            </div> */}
                        </div>
                    </div>
                </nav>
            </>
        );
    };
};

export default Navbar;
